<script lang="ts" setup>
import { onMounted, ref, watch, computed, onUnmounted } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import AppHeader from '@/components/AppHeader.vue';
import AppMobileNav from '@/components/AppMobileNav.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import { getInitialUserLocalization } from '@/utils/helpers';
import { handleUserLocale } from '@/i18n';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import NoConnection from '@/components/NoConnection.vue';
import { useAppRerender } from '@/utils/composables/useAppRerender';
import { useBlockScroll } from '@/utils/composables/useBlockScroll';
import { useCapacitorPlugins } from '@/utils/composables/useCapacitorPlugins';
import { providePrimeSurveys } from '@/utils/composables/useIntegrationScriptRender';
import { providePrimeIcons } from '@primeinsightsgroupllc/prime-icons';
import {
	providePrimeTheme,
	PrimeLoaderSpinner,
	PrimeNotificationsContainer,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import SurveysProgress from '@/components/common/SurveysProgress.vue';
import PageTitle from '@/components/page-wrapper/PageTitle.vue';
import PageWrapper from '@/components/page-wrapper/PageWrapper.vue';
import { useAppPusherEventListener } from '@/utils/composables/app/useAppPusherEventListener';
import { useAppNotificationListener } from '@/utils/composables/app/useAppNotificationListener';
import { useAppAnnouncementsListener } from '@/utils/composables/app/useAppAnnouncementsListener';
import { useAppInit } from '@/utils/composables/app/useAppInit';
import { providePrimeChat } from '@/utils/composables/useIntegrationChatRender';
import PrimeChat from '@/components/PrimeChat.vue';
import { useDev } from '@/utils/composables/useDev';
import { useAppStore } from '@/stores/app';
import {
	PrimeModalWindow,
	useModalStorage,
} from '@primeinsightsgroupllc-modals/prime-modals';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { MODAL_MAP } from './constants/modals-map';

const { setThemeColors } = providePrimeTheme();
const { handleRouteChange, shiftModal } = useModalStorage();
const { isMobileApp } = useMobileApp();
const { isDev } = useDev();
const { setIconsColors } = providePrimeIcons();
const { localizationCookie } = useAppCookies();
const route = useRoute();
const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);
const { appKey } = useAppRerender();
const appStore = useAppStore();
const { isNetworkConnected, initCapacitor, deinitCapacitor } =
	useCapacitorPlugins();

setThemeColors({
	'primary-base': '#4c6707',
	'secondary-base': '#cdef84',
	'text-default': '#171e09',
	'text-disabled': '#76786b',
	'state-success': '#4C6707',
	'grey-50': '#efeee6',
	'grey-100': '#e2e4d4',
	'grey-200': '#eae8e0',
	'grey-300': '#c6c8b9',
	'grey-400': '#f5f5f5',
	'grey-500': '#93968c',
	'grey-600': '#475467',
	'grey-700': '#76786b',
	'grey-800': '#45483c',
});

setIconsColors({
	'base-primary': '#4c6707',
	'base-secondary': '#cdef84',
	'text-default': '#171e09',
	'text-disabled': '#76786B',
});
providePrimeSurveys();
providePrimeChat();
useBlockScroll();

useAppInit();
useAppAnnouncementsListener();
useAppPusherEventListener();
useAppNotificationListener();

const isProgressVisible = ref(false);
const isMobileNavVisible = ref(false);
const initLoading = ref(false);

const isLoginPage = computed(
	() => route.name === 'app-login' || route.name === 'home'
);
const isLightBg = computed(() => {
	return (
		route.meta.authRoute ||
		!isNetworkConnected.value ||
		(!isAuthorized && route.name === 'email-subscriptions')
	);
});
const isTitleVisible = computed(() => {
	return route.meta.title;
});

const isChatAvailable = computed(() => {
	if (import.meta.env.VITE_PC_SCOPE === 'dev') {
		return isDev.value;
	} else {
		return import.meta.env.VITE_PC_SCOPE === 'all';
	}
});

watch([isAuthorized, route], (data) => {
	const [isAuthorized, route] = data;
	const isVisible = Boolean(isAuthorized && route.meta.progress);

	if (isProgressVisible.value !== isVisible) {
		isProgressVisible.value = isVisible;
	}
});

watch([isAuthorized, route], (data) => {
	const [isAuthorized, route] = data;
	const isVisible = Boolean(isAuthorized && !route.meta.mobileNavHidden);

	if (isMobileNavVisible.value !== isVisible) {
		isMobileNavVisible.value = isVisible;
	}
});

watch(
	() => route.name,
	async () => await handleRouteChange(route.name as string)
);

onMounted(async () => {
	if (!isMobileApp) {
		window.addEventListener('beforeunload', shiftModal);
	}

	await initCapacitor();
	let localization = localizationCookie;

	if (!localization) {
		initLoading.value = true;
		try {
			localization = await getInitialUserLocalization();
		} catch (e) {
			sendErrorInfo(e);
		} finally {
			initLoading.value = false;
		}
	}

	await handleUserLocale(localization);
});

onUnmounted(() => {
	deinitCapacitor();
	appStore.clearLog();
});
</script>

<template>
	<ErrorBoundary>
		<div
			id="page"
			class="app-layout"
			:class="{ 'light-bg': isLightBg, 'white-bg login-page': isLoginPage }"
		>
			<template v-if="isNetworkConnected">
				<PrimeLoaderSpinner v-if="initLoading" />
				<template v-else>
					<PageWrapper :key="appKey">
						<template #appTopArea>
							<AppHeader />
							<PageTitle v-if="isTitleVisible" />
							<SurveysProgress v-if="isProgressVisible" />
						</template>
						<RouterView />
						<template #appMainContent>
							<RouterView />
						</template>
						<template v-if="isMobileNavVisible" #appBottomArea>
							<AppMobileNav />
						</template>
					</PageWrapper>
					<PrimeNotificationsContainer />
					<!-- Modals -->
				</template>
				<PrimeModalWindow :modals="MODAL_MAP" app="fivesurveys" />
				<!--TODO: Refactor to reinit resize observer as exposed method -->
				<PrimeChat v-if="isChatAvailable && isAuthorized" :key="appKey" />
			</template>
			<PageWrapper v-else>
				<template #appMainContent>
					<NoConnection />
				</template>
			</PageWrapper>
		</div>
	</ErrorBoundary>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	min-height: 100svh;
	overflow-y: auto;
	background-color: var(--5s-page-background);

	@include breakpoint(tablet) {
		min-height: 100vh;
	}

	&.light-bg {
		--5s-body-background: var(--5s-page-background);
	}
	&.white-bg {
		--5s-body-background: #fff;
		background: #fff;
	}
}
</style>

import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import {
	CODE_REQUIRED,
	CODE_SENT,
	CODE_SENT_MESSAGE,
	CODE_SENT_QUESTION,
	LOGIN,
	RESEND,
	RESEND_CODE,
} from '@/constants/locales';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useComponentName } from '@/utils/composables/useComponentName';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useI18n } from 'vue-i18n';

export const useSignupCompleteView = (): {
	isMobileApp: boolean;
	isLoading: Ref<boolean>;
	resendVerificationEmail: () => Promise<void>;
	verifyCode: () => Promise<void>;
	code: Ref<string>;
	codeError: Ref<string>;
	handleUpdateCode: (updatedCode: string) => void;
	cooldownButtonEl: Ref<{ restartTimer: () => void } | null>;
} => {
	// TS has error that says that imports in the variable are not used, but they are
	// To fix this, we need to add this variable to the script
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const variablesToFixBuild = ref([
		CODE_SENT,
		CODE_SENT_MESSAGE,
		CODE_SENT_QUESTION,
		LOGIN,
		RESEND,
		RESEND_CODE,
	]); // workaround for build error
	variablesToFixBuild.value = []; // workaround for build error

	const { isMobileApp } = useMobileApp();
	const componentName = useComponentName();
	const authStore = useAuthStore();
	const { codeErrorContent } = storeToRefs(authStore);

	const isLoading = ref(false);
	const cooldownButtonEl = ref<{ restartTimer: () => void } | null>(null);
	const code = ref('');
	const codeError = ref('');

	const resendVerificationEmail = async () => {
		try {
			isLoading.value = true;
			const response = await authStore.resendEmail();

			if (response?.status === 200) {
				cooldownButtonEl.value?.restartTimer();
				notify({ body: response?.data.message });
			} else {
				notify({ body: authStore.error?.message || '' });
			}
		} catch (error) {
			notify({ body: authStore.error?.message || '' });
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	const verifyCode = async () => {
		try {
			isLoading.value = true;
			await authStore.verifyCode(code.value);
		} catch (error) {
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	const handleUpdateCode = (updatedCode: string) => {
		code.value = updatedCode;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const variableToFixBuild = ref([handleUpdateCode, verifyCode]); // workaround for build error

	const { t } = useI18n();

	watch(code, (newValue, oldValue) => {
		codeError.value =
			oldValue.length && !newValue.length ? t(CODE_REQUIRED) : '';
	});

	watch(codeErrorContent, (newError) => {
		codeError.value = newError?.message || '';
	});

	return {
		isMobileApp,
		isLoading,
		resendVerificationEmail,
		verifyCode,
		code,
		codeError,
		handleUpdateCode,
		cooldownButtonEl,
	};
};

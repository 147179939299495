<script lang="ts" setup>
import { ref, watch, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { changeTimeZone } from '@/api';
import {
	PrimeButton,
	PrimeDropdown,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { timezones } from '@/constants/timezones';
import { CHANGE_TIMEZONE, SAVE_CHANGES } from '@/constants/locales';
import {
	TEST_MODAL_TIMEZONE_SELECT,
	TEST_MODAL_TIMEZONE_SAVE,
} from '@/constants/dataTestAttrs';
import { SEARCH } from '@/constants/locales';
type TimeZone = {
	code: string;
	label: string;
};

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const userStore = useUserStore();
const loading = ref(false);
const { timezone } = storeToRefs(userStore);
const userTimezone = ref<TimeZone>();

const setUserTimezone = () => {
	const tz = timezones.find(
		(tz: Record<string, string>) => tz.code === timezone.value
	);
	if (!tz) return;
	userTimezone.value = { code: tz.code, label: tz.label };
};

watch(
	() => timezone,
	(value) => {
		if (value) {
			setUserTimezone();
		}
	}
);

onBeforeMount(() => {
	setUserTimezone();
});

const handleSubmit = async () => {
	try {
		loading.value = true;
		if (!userTimezone.value) {
			throw new Error('Timezone is not set');
		}
		await changeTimeZone(userTimezone.value.code);
		userStore.setTimezone(userTimezone.value.code);
		handleCloseModal();
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CHANGE_TIMEZONE) }}</template>
		<template #inputs>
			<PrimeDropdown
				v-model="userTimezone"
				:data="timezones"
				:filter-placeholder="$t(SEARCH)"
				:data-test="TEST_MODAL_TIMEZONE_SELECT"
				filter
			/>
		</template>
		<template #footer>
			<PrimeButton
				type="text"
				variant="grey"
				rounded="md"
				:label="$t(SAVE_CHANGES)"
				:data-test="TEST_MODAL_TIMEZONE_SAVE"
				@click="handleSubmit"
			/>
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.p-modal-layout {
	:deep(.content-inputs) {
		margin-top: 0;
	}

	:deep(.content-inputs .p-dropdown) {
		width: 100%;

		.p-dropdown-label {
			text-align: start;
		}
	}
}
</style>

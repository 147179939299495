<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import modalImage from '@/assets/icons/congrats.svg';
import ProgressBar from '@/components/common/ProgressBar.vue';
import { COMPLETE_MORE, FIRST_COMPLETE, GREAT_JOB } from '@/constants/locales';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';
import { transformCurrency } from '@/utils/helpers';

const { isMobile } = useScreenSize();

const { fiveSurveysIncome, currency } = storeToRefs(useUserStore());

const incomeText = computed(() => {
	return `${transformCurrency(+fiveSurveysIncome.value! || 0, currency.value)}`;
});
</script>

<template>
	<ModalCommonContent class="first-survey-completed-modal text-center">
		<template #header>{{ $t(GREAT_JOB) }} 💚</template>
		<template #icon>
			<img :src="modalImage" alt="" />
		</template>
		<template #title>
			<div>
				<PrimeText tag="div" :size="isMobile ? 'base' : '2xl'" weight="500">
					{{ $t(FIRST_COMPLETE) }}
				</PrimeText>
			</div>
		</template>
		<template #message>
			<div class="progress-wrapper">
				<ProgressBar :progress="1" variant="light" />
				<PrimeText
					tag="p"
					:size="isMobile ? 'sm' : 'base'"
					v-html="$t(COMPLETE_MORE, { value: incomeText })"
				/>
			</div>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.first-survey-completed-modal {
	:deep(.content-icon) {
		width: 12.5rem;
		height: 12.5rem;
	}

	& .progress-wrapper {
		margin-top: 0.5rem;
		display: flex;
		flex-direction: column;
		gap: 0.75rem;

		& :deep(.progress-bar:not(.active)) {
			background-color: var(--p-grey-450);
		}
	}
}
</style>

<script setup lang="ts">
import {
	ACCOUNT_EXISTS,
	CONTINUE,
	LINK_ACCOUNTS_MESSAGE,
	NEED_NEW_PASSWORD,
	PASSWORD,
	SIGN_IN,
} from '@/constants/locales';
import { PrimeInput, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import VerifyEmail from '@/views/signin/components/VerifyEmail.vue';
import { useSigninView } from '@/views/signin/composables';
import {
	PrimeButton,
	PrimePinInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiEmailAttach, PiLock } from '@primeinsightsgroupllc/prime-icons';

const {
	isLoading,
	isLinkExistingToSocial,
	handleSubmitForm,
	handleForgotPassword,
	authUserEmail,
	form,
	getValidationFieldStatus,
	getValidationFieldText,
	sendOneTimeLink,
	validationSubMessage,
	isEmailNotVerified,
	isMobileApp,
	code,
	codeError,
	handleUpdateCode,
	isSubmitDisabled,
} = useSigninView();
</script>

<template>
	<AuthPageContent
		:title="$t(isLinkExistingToSocial ? ACCOUNT_EXISTS : SIGN_IN)"
	>
		<PrimeText
			v-if="isLinkExistingToSocial"
			size="xs"
			class="link-accounts-message"
		>
			{{ $t(LINK_ACCOUNTS_MESSAGE) }}
		</PrimeText>
		<form class="signin-form" @submit.prevent="handleSubmitForm">
			<input
				type="email"
				style="display: none"
				:value="authUserEmail"
				autocomplete="username"
			/>
			<PrimeInput
				v-model="form.password"
				autofocus
				autocomplete="password"
				type="password"
				:placeholder="$t(PASSWORD)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>
			<PrimeButton
				v-if="validationSubMessage"
				type="link"
				class="warning-link"
				:label="validationSubMessage"
				@click="sendOneTimeLink"
			>
				<template #iconLeft>
					<PiEmailAttach color="base-primary" />
				</template>
			</PrimeButton>
			<PrimeButton
				type="link"
				variant="primary"
				class="forgot-password-button"
				:label="$t(NEED_NEW_PASSWORD)"
				@click="handleForgotPassword"
			>
				<template #iconLeft>
					<PiLock color="base-primary" />
				</template>
			</PrimeButton>

			<VerifyEmail v-if="isEmailNotVerified" />

			<PrimePinInput
				v-if="isEmailNotVerified && isMobileApp"
				:model-value="code"
				:error="codeError"
				class="pin-input"
				@update:model-value="handleUpdateCode"
			/>

			<div class="action-wrapper">
				<PrimeButton
					class="submit-login-button"
					role="submit"
					variant="secondary"
					:disabled="isSubmitDisabled"
					:label="$t(CONTINUE)"
				/>
			</div>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.signin-form {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.action-wrapper {
	flex: 1 1 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.p-btn--link.warning-link {
	margin-top: 0.75rem;
}

button.p-btn--link.forgot-password-button,
button.p-btn--link.warning-link {
	padding: 0.5rem 0;
	align-items: flex-end;
	align-self: flex-start;
}

.link-accounts-message {
	text-align: start;
	margin-bottom: 0.75rem;
}

.pin-input {
	margin-top: 0.875rem;
}
</style>

<script setup lang="ts">
import TransactionsView from '@/views/history/views/transactions/TransactionsView.vue';
import RewardsView from '@/views/history/views/rewards/RewardsView.vue';
import { REWARDS, TRANSACTIONS } from '@/constants/locales';
import { useHistoryView } from '@/views/history/composables';
import {
	PrimeTabView,
	PrimeTabPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { activeTabId } = useHistoryView();
</script>

<template>
	<main class="history">
		<PrimeTabView v-model="activeTabId">
			<PrimeTabPanel id="transactions" :header="$t(TRANSACTIONS)">
				<TransactionsView />
			</PrimeTabPanel>
			<PrimeTabPanel id="rewards" :header="$t(REWARDS)">
				<RewardsView />
			</PrimeTabPanel>
		</PrimeTabView>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.history {
	width: 100%;
	height: 100%;

	@include breakpoint(mobile) {
		width: 40rem;
		border-radius: 0.75rem 0.75rem 0 0;
	}
}

:deep(.p-tab-content) {
	padding: 0.75rem 0;
}
:deep(.p-tabs) {
	border-bottom: 2px solid var(--p-grey-300);
	overflow: visible;
}
:deep(.p-tab-button) {
	margin-bottom: -2px;
	z-index: 2;
}
:deep(.p-active-tab.p-tab-button:after) {
	height: 0.125rem;
}
:deep(.p-active-tab.p-tab-button:after) {
	background: var(--p-grey-950);
}
</style>

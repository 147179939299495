export enum ClaimStatuses {
	SUCCESS = 'success',
	FAILED = 'error',
	PROCESSING = 'processing',
	REFUND = 'refunded',
	USER_CONFIRMATION = 'user_confirmation',
}

export enum RewardCategories {
	TRANSFERS = 'transfers',
	GIFT_CARDS = 'gift_cards',
	PREPAID_CARDS = 'prepaid_cards',
	CHARITY = 'charity',
}

export enum Cookies {
	LOCALIZATION = 'localization',
	AUTH_TOKEN = 'auth-token',
	M_ID = 'm_id',
	AUTH_PROVIDER = 'auth-provider',
}

export enum SocialProviders {
	// TODO enable facebook when it will be available
	FACEBOOK = 'facebook',
	GOOGLE = 'google',
	APPLE = 'apple',
}

export enum SignatureActions {
	DEFAULT = 'default',
	VERIFY_EMAIL = 'verify_email',
	ONE_TIME_TOKEN = 'one_time_token',
	CLAIM_USER_CONFIRMATION = 'claim_user_confirmation',
	EMAIL_TRANSFER = 'email_transfer',
}

export enum Platforms {
	WEB = 'web',
	WEB_MOBILE = 'web-mobile',
	IOS = 'ios',
	ANDROID = 'android',
}

export enum CapgoChannels {
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum PostMessages {
	SURVEY_STARTED = 'ps-survey-started',
	SURVEY_COMPLETED = 'ps-survey-completed',
	SCROLL_BLOCK = 'ps-lock-scroll',
	QUALIFICATION_IN_PROGRESS = 'ps-qualification-in-progress',
}

export enum TrackingEvents {
	SIGN_UP = 'sign_up',
	SURVEY_STARTED = 'survey_started',
	SURVEY_COMPLETED = 'survey_completed',
	SIGN_UP_CONFIRMED = 'sign_up_confirmed',
	CLAIM_REQUESTED = 'claim_requested',
	APP_INIT = 'app_init',
}

export enum ClaimErrorStatuses {
	NEW_CLAIM_DATA_USED = 417,
	CONFIRM_CLAIM_VIA_EMAIL = 428,
	NOT_VALID_DATA_USED = 404,
}

export enum Environments {
	DEV = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum MessageChannels {
	NOTIFICATION = 'notification',
	ANNOUNCEMENT = 'announcement',
	WEBSOCKET = 'websocket',
}

export enum AnnouncementTypes {
	STATIC = 1,
	DYNAMIC = 2,
}

export enum InternalRewards {
	PAYPAL = 'PAYPAL_INTERNAL',
	REVOLUT = 'REVOLUT_INTERNAL',
}

export enum PromotedRewards {
	REVOLUT = InternalRewards.REVOLUT,
}

import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted } from 'vue';
import type { UserBalanceUpdateInfo } from '@/types';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE, UPDATED_PROGRESS } from '@/constants/locales';
import {
	COMPLETED_5_SURVEYS_MODAL,
	FIRST_SURVEY_COMPLETED_MODAL,
	HALF_PROGRESS_NOTIFICATION_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels, TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';

export const useAppPusherEventListener = (): void => {
	const { createNewModal } = useModalStorage();
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const userStore = useUserStore();
	const appStore = useAppStore();

	watch(
		() => userId.value && userAuthToken.value,
		async () => {
			if (!window.Echo && userAuthToken.value) {
				await echo.createConnection(userAuthToken.value);
			}
			if (userId.value && window.Echo) {
				channelName.value = `user-notifications-${userId.value}`;
				window.Echo.private(channelName.value)
					.listen(
						// Progress update
						'.user-survey-taken',
						({ progress }: { progress: number }) => {
							userStore.setProgress(progress);
							notify({ body: t(UPDATED_PROGRESS) });
						}
					)
					.listen(
						// Balance update
						'.balance-updated',
						(event: UserBalanceUpdateInfo) => {
							userStore.setBalance(event.new_balance);
							userStore.setProgress(event.progress);
							createNewModal(COMPLETED_5_SURVEYS_MODAL, {
								shared: true,
							});
							if (!event.is_silent) {
								notify({ body: t(UPDATED_BALANCE) });
							}
						}
					)
					.listen(
						//Show user modal on first survey complete
						'.user_first_survey_complete',
						() => {
							createNewModal(FIRST_SURVEY_COMPLETED_MODAL, { shared: true });
						}
					)
					.listen(
						//Show user modal on first survey complete
						'.one_time_intro_to_half_surveys',
						({ notification_id }: { notification_id: string }) => {
							createNewModal(HALF_PROGRESS_NOTIFICATION_MODAL, {
								id: notification_id,
								channel: MessageChannels.WEBSOCKET,
								shared: true,
							});
						}
					)
					.listen(
						//Show user modal with referrals description
						'.introduce_to_referrals',
						({ notification_id }: { notification_id: string }) => {
							createNewModal(REFERRALS_NOTIFICATION_MODAL, {
								id: notification_id,
								channel: MessageChannels.WEBSOCKET,
								shared: true,
							});
						}
					)
					.listen(
						'.gtm_track_event',
						async ({
							event_name,
							event_name_adjust,
							params,
						}: {
							event_name: TrackingEvents;
							event_name_adjust: string;
							params: Record<string, any>;
						}) => {
							appStore.addLog(
								`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
							);
							await appStore.trackEvent(event_name, params, event_name_adjust);
						}
					);
			}
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		echo.removeConnections();
	});
};

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useAuthStore } from '@/stores/auth';
import { AnnouncementTypes, MessageChannels } from '@/enums';
import type { Announcement } from '@/types';
import { DYNAMIC_ANNOUNCEMENT_MODAL } from '@/constants/modals';

export const useAppAnnouncementsListener = (): void => {
	const { createNewModal } = useModalStorage();
	const { announcementsList } = storeToRefs(useMessagesStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { readAnnouncement } = useMessagesStore();

	const showStaticAnnouncementModal = ({ uuid, name }: Announcement): void => {
		const requiredOptions = {
			id: uuid,
			channel: MessageChannels.ANNOUNCEMENT,
			shared: true,
			onClose: () => readAnnouncement(uuid),
		};
		switch (name) {
			default:
				break;
		}
	};

	const showDynamicAnnouncementModal = (announcement: Announcement) => {
		if (!announcement.dynamic_data) return;
		createNewModal(DYNAMIC_ANNOUNCEMENT_MODAL, {
			id: announcement.uuid,
			channel: MessageChannels.ANNOUNCEMENT,
			content: announcement.dynamic_data,
			shared: true,
			onClose: () => readAnnouncement(announcement.uuid),
		});
	};

	watch(
		[userAuthToken, announcementsList],
		async (newValue) => {
			const [token, announcements] = newValue;

			if (token && announcements?.length) {
				// Show announcement modals according to the announcement name
				announcements?.forEach((announcement) => {
					switch (announcement.type) {
						case AnnouncementTypes.STATIC:
							showStaticAnnouncementModal(announcement);
							break;
						case AnnouncementTypes.DYNAMIC:
							showDynamicAnnouncementModal(announcement);
							break;
						default:
							break;
					}
				});
			}
		},
		{ immediate: true }
	);
};

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import PageCard from '@/components/common/PageCard.vue';
import {
	PrimeText,
	PrimeDivider,
	PrimeLoaderSpinner,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useUserStore } from '@/stores/user';
import type { ProfileStatsItem } from '@/types';

defineProps<{
	stats: ProfileStatsItem[];
	isLoaded: boolean;
}>();

const { isMobileOnly } = useScreenSize();
const { email } = storeToRefs(useUserStore());
</script>

<template>
	<PageCard class="stats" size="sm">
		<template v-if="isLoaded">
			<div class="stats-container">
				<div v-if="email" class="stats-top">
					<div>
						<PrimeText weight="600">{{ $t('email') }}: </PrimeText>
						<PrimeText class="stats-user-email">{{ email }}</PrimeText>
					</div>
					<PrimeDivider />
				</div>
				<div class="stats-items">
					<div v-for="item in stats" :key="item.title" class="stats-item">
						<component
							:is="item.icon"
							:size="isMobileOnly ? '1.5rem' : '2rem'"
							:color="item.iconColor"
						/>
						<div class="stats-item-right">
							<PrimeText :size="isMobileOnly ? 'sm' : 'xl'" weight="700">
								{{ item.value }}
							</PrimeText>
							<PrimeText size="sm" color="grey-700">
								{{ item.title }}
							</PrimeText>
						</div>
					</div>
				</div>
			</div>
		</template>
		<PrimeLoaderSpinner v-else />
	</PageCard>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.stats {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.875rem;
	min-height: 7.5rem;
}

.stats-container {
	width: 100%;
}

.stats-top {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.875rem;
	margin-bottom: 0.875rem;
}

.stats-items {
	display: flex;
	justify-content: space-around;
	gap: 1rem;
}

.stats-item {
	flex: 1;
	display: flex;
	align-items: center;
	gap: 0.625rem;

	@include breakpoint(mobileonly) {
		& {
			flex: unset;
		}
	}

	svg {
		flex: none;
	}
}

.stats-item-right {
	display: flex;
	flex-direction: column;
}

.stats-user-email {
	word-break: break-word;
}
</style>

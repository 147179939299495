import { useComponentName } from '@/utils/composables/useComponentName';
import type { ComputedRef, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import {
	EMAIL_SUB_ACCOUNT_UPDATES,
	EMAIL_SUB_ACCOUNT_UPDATES_DESCRIPTION,
	EMAIL_SUB_OFFERS,
	EMAIL_SUB_OFFERS_DESCRIPTION,
	EMAIL_SUB_REMINDERS,
	EMAIL_SUB_REMINDERS_DESCRIPTION,
	EMAIL_SUB_SURVEY_INVITATIONS,
	EMAIL_SUB_SURVEY_INVITATIONS_DESCRIPTION,
	EMAIL_SUB_WEEKLY_REPORT,
	EMAIL_SUB_WEEKLY_REPORT_DESCRIPTION,
	EMAIL_SUBS_NOT_UPDATED,
	EMAIL_SUBS_UPDATED,
} from '@/constants/locales';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useRoute } from 'vue-router';
import {
	authProfileEmailSubs,
	getProfileEmailSubscriptions,
	getUnauthEmailSubs,
	unauthProfileEmailSubs,
} from '@/api';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';

type SubscriptionItem = {
	type: string;
	subscribed: boolean;
};

export const useEmailSubscriptionsView = (): {
	isAllSubsShow: Ref<boolean>;
	isAuthorized: Ref<boolean>;
	subsToShow: ComputedRef<SubscriptionItem[] | null>;
	emailSubsTranslations: Ref<Record<string, Record<string, string>>>;
	handleSave: () => Promise<void>;
} => {
	const componentName = useComponentName();

	// TODO: Update translations for 5 surveys
	const emailSubsTranslations = ref<Record<string, Record<string, string>>>({
		'special-offers': {
			title: EMAIL_SUB_OFFERS,
			description: EMAIL_SUB_OFFERS_DESCRIPTION,
		},
		reminders: {
			title: EMAIL_SUB_REMINDERS,
			description: EMAIL_SUB_REMINDERS_DESCRIPTION,
		},
		'account-updates': {
			title: EMAIL_SUB_ACCOUNT_UPDATES,
			description: EMAIL_SUB_ACCOUNT_UPDATES_DESCRIPTION,
		},
		'weekly-report': {
			title: EMAIL_SUB_WEEKLY_REPORT,
			description: EMAIL_SUB_WEEKLY_REPORT_DESCRIPTION,
		},
		'survey-invitations': {
			title: EMAIL_SUB_SURVEY_INVITATIONS,
			description: EMAIL_SUB_SURVEY_INVITATIONS_DESCRIPTION,
		},
	});

	const { t } = useI18n();
	const { isAuthorized } = storeToRefs(useAuthStore());
	const route = useRoute();
	const { uuid, token, type } = route.query;

	const subscriptions = ref<SubscriptionItem[] | null>(null);
	const isAllSubsShow = ref(false);

	const subsToShow = computed<SubscriptionItem[] | null>(() => {
		let items = null;

		if (subscriptions.value?.length) {
			if (isAuthorized.value) {
				items = [...subscriptions.value];
			} else {
				items = isAllSubsShow.value
					? [...subscriptions.value]
					: subscriptions.value.slice(0, 1);
			}
		}

		return items;
	});

	const fetchSubscriptionPreferences = async () => {
		if (isAuthorized.value) {
			const data = await getProfileEmailSubscriptions();
			if (data) {
				subscriptions.value = data;
			}
		} else {
			const data = await getUnauthEmailSubs(uuid as string, token as string);

			if (data) {
				const subscriptionIndexByType = data.findIndex(
					(item: SubscriptionItem) => item.type === type
				);
				const firstSubscription = data[type ? subscriptionIndexByType : 0];
				subscriptions.value = [firstSubscription].concat(
					data.filter((item: SubscriptionItem) => item.type !== type)
				);
			}
		}
	};

	onMounted(async () => {
		await fetchSubscriptionPreferences();
	});

	const handleSave = async () => {
		try {
			if (isAuthorized.value) {
				await authProfileEmailSubs(subscriptions.value);
				notify({ body: t(EMAIL_SUBS_UPDATED) });
			} else {
				if (uuid && token && subscriptions.value) {
					await unauthProfileEmailSubs(
						uuid as string,
						token as string,
						subscriptions.value
					);
					notify({ body: t(EMAIL_SUBS_UPDATED) });
				}
			}
		} catch (error) {
			notify({ body: t(EMAIL_SUBS_NOT_UPDATED) });
			sendErrorInfo(error, componentName);
		}
	};

	return {
		isAllSubsShow,
		isAuthorized,
		subsToShow,
		emailSubsTranslations,
		handleSave,
	};
};

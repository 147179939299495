import API from '@/api';
import type { Transaction } from '@/types';

export const getTransactions = async (
	per_page: number,
	page: number
): Promise<Transaction> => {
	const { data } = await API.get('/api/transactions', {
		params: { per_page: per_page, page: page },
	});
	return data;
};
